body {
    margin: 0px;    
}

.footer-body {
    background-image: url('./img/Rectangle\ 4.svg');
    max-width: 100%;
    height: 117px;
    background-position: 1.25rem center;
    background-repeat: no-repeat;
    background-size: contain;
    /* background-color: red; */
    margin-top: 0px !;
    display: flex;
    justify-content: space-evenly;
    padding-right: 6%;
    margin-bottom: 20px;
}

.location {
    /* background-color: #FFFFFF; */
    padding-top: 4%;
    font-family: 'Poppins', sans-serif;
    color: #000000;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 21px;

}

.benefits {
    /* background-color: #FFFFFF; */
    padding-top: 4%;
    font-family: 'Poppins', sans-serif;
    color: #000000;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 21px;
}

.club-fit {
    background-color: #60D9FF;
    padding-top: 4%;
    margin-right: 4%;
    width: 28%;
    text-align: center;
    border-radius: 20px;
    color: #FFFFFF;
    font-family: 'Poppins', sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 21px;

}

.line {
    width: 2px;
    background-color: #DFDFDF;
    height: 95px;
    margin-top: 10px;
}