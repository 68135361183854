.dots {
    /* main container  */
    background-color: #FFFFFF;
    z-index: 1!important;
    display: flex;
    justify-content: center;
}

.form {
    display: flex;
    justify-content: center;
    flex-direction: column;
    padding: 12px 12px 9% 12px;
    width: auto;
    margin-top: 6rem;
    z-index: 5;
}

.header-form {
    display: flex;
    align-self: center;
    justify-content: flex-start;
    flex-direction: column;
    width: 550px;
    padding: 12px;
    /* width: auto; */
}

.header-form .titl {
    font-family: 'Open Sans', sans-serif;
    color: #3751FE;
    font-style: normal;
    font-weight: 700;
    font-size: 36px;
    /* line-height: 49px; */
}

.header-form .sub-titl {
    /* background-color: red; */
    font-family: 'Roboto', sans-serif;
    color: rgba(0, 0, 0, 0.6);
    font-style: normal;
    font-size: 18px;
    /* line-height: 21px; */
    font-weight: 400;
}

.box-c {
    /* background-color: red; */
    align-self: center;
    background: #FFFFFF;
    border: 1px solid #C1BBBB;
    min-width: 416px;
    min-height: 56px;
    margin-top: 24px;
    display: flex;
    flex-direction: column;
}

.box-p {
    background: #FFFFFF;
    align-self: center;
    border: 1px solid #C1BBBB;
    min-width: 416px;
    min-height: 56px;
    margin-top: 24px;
    display: flex;
    flex-direction: column;
    z-index: 1;
}

.backend-error-section {
    /* background-color: aliceblue; */
    margin-top: 3px;
    height: auto;
    width: 550px;
    align-self: center;
    padding: 4px;
}

.btn-account {
    font-family: 'Roboto', sans-serif;
    background: #3751FE;
    width: 550px;
    align-self: center !important;
    height: 41px;
    margin-top: 3rem;
    box-shadow: 0px 4px 3px rgba(0, 0, 0, 0.25);
    border: none;
    color: #FFFFFF;
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    line-height: 23px;
    transition: transform 0.2s;
    cursor: pointer;
    transform: translateX(1%);
}

.btn-account:active {
    transform: translateY(2px);
}

.reds {
    margin-top: 3rem;
    width: 550px;
    padding: 2px;
}

.reds .login-wit {
    font-family: 'Roboto', sans-serif;
    padding-right: 5rem;
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 21px;
    color: rgba(0, 0, 0, 0.61);
}

.box-texto {
    font-weight: bold;
    font-family: 'Roboto', sans-serif;
    border: none;
    margin-top: 3px;
    margin-left: 1rem;
    width: auto;
    height: auto;
    left: 326px;
    top: 552px;
    font-style: normal;
    /* font-weight: 500; */
    font-size: 14px;
    /* line-height: 21px; */
    line-height: 18px;
    color: #3751FE;
}

.text {
    font-family: 'Roboto', sans-serif;
    color: rgba(0, 0, 0, 0.61);
    width: auto;
    height: 21px;
    left: 326px;
    top: 512px;
    font-weight: 400;
    margin-top: 3px;
    font-style: normal;
    font-size: 16px;
    line-height: 21px;
    margin-left: 1rem;
}

.box-texto:focus {
    outline: none;
}

/* googl y marca, probablemente quedaran deprecadas en ./registro */
.googl {
    font-family: 'Roboto', sans-serif;
    font-style: normal;
    font-weight: 700;
    font-size: 18px;
    line-height: 21px;
    color: #3751FE;
}

.marca {
    position: absolute;
    left: 65%;
    font-family: 'Poppins', sans-serif;
    width: 177px;
    height: 46px;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    color: black;
    margin-bottom: 100px;
}

.select-signin {
    padding: 10px;
    font-size: 16px;
    border: 0px;
    background-color: #fff;
    cursor: pointer;
    width: 100%;
    appearance: none;
}

.select-signin:focus {
    border-color: #007bff;
    outline: none;
}
.select-signin option{
    border-radius: 0px;
    background-color: #FFFFFF; 
    color: #333; 
    font-size: 16px; 
    padding: 8px 16px;
    border-bottom: 0px;
    cursor: pointer; 
}
.select-signin option:checked{
    background-color: #3751FE; /* Cambia el color de fondo de la opción seleccionada */
    color: #fff; 
}

.select-container{
    position: relative;
}
.mui-arrow-icon{
    position: absolute;
    top: 40%;
    right: 6px;
}

/* .box-c::before {
    content: "";
    border-left: 2px solid #0C31F1;
    
    display: block;
    
    position: absolute;
}

/* nuevo responsivo */
/* laptops */
@media (min-width: 1081px) and (max-width: 1560px) {

    .header-form,
    .btn-account,
    .backend-error-section,
    .box-c,
    .box-p {
        width: 400px;
    }

    .box-c,
    .box-p {
        min-height: 60px;
    }

    .cubrir {
        background-image: none;
    }

    .dots {
        display: flex;
        justify-content: flex-start;
    }

    .form {
        margin-left: 15%;
    }
    select{
        width:100%;
    }
}

/* smalls */
@media (max-width: 1080px){
    .header-form {
        width: 100%;
    }

    .box-c,
    .box-p {
        width: 100%;
        height: 60px;
    }
    .btn-account {
        height: 60px;
    }
    .btn-account,
    .backend-error-section {
        width: 100%;
    }

    .box-c,
    .box-p {
        min-height: 30px;
    }
    .form {
        margin-left: 5%;
        margin-top: 0;
        width: 100%;
    }
    select{
        width:80%;
    }
}
/*xsm devices*/
@media (max-width: 390px){
    .header-form {
        width: 100%;
    }

    .box-c,
    .box-p {
        width: 100%;
    }

    .btn-account,
    .backend-error-section {
        width: 100%;
    }

    .box-c,
    .box-p {
        min-height: 30px;
    }

    .cubrir {
        background-image: none;
    }

    .dots {
        display: flex;
        justify-content: flex-start;
    }

    .form {
        /* margin-left: 5%; */
        width: 100%;
        margin-top: 0;
    }
    select option{
        width:100px;
    }
}

/* mayores a 1500 */
/* before changes @media (min-width: 1561px)and (max-width: 3400px){ */
@media (min-width: 1561px)and (max-width: 2000px) {

    .header-form,
    .btn-account,
    .backend-error-section,
    .box-c,
    .box-p {
        width: 700px;
    }

    .box-c,
    .box-p {
        min-width: 700px;
        min-height: 60px;
        padding-right: 3px;
    }

    .dots {
        justify-content: flex-start;
    }

    .form {
        width: auto;
        margin-left: 10%;
    }
}

/* XL resolution Devices */
@media (min-width: 2001px)and (max-width: 3200px) {

    .header-form,
    .btn-account,
    .backend-error-section,
    .box-c,
    .box-p {
        width: 1000px;
    }

    .btn-account {
        min-height: 50px;
    }

    .box-c,
    .box-p {
        min-width: 700px;
        min-height: 90px;
        padding-right: 3px;
    }

    .dots {
        justify-content: flex-start;
    }

    .form {
        width: auto;
        margin-left: 20%;
        z-index: 5;
    }
}