.formulario {
    background-image: url('../img/dots.svg');
    background-repeat: no-repeat;
    background-size: 292px;
    background-position: right 150px top 5.8rem;
    margin-bottom: 0;
    position: relative;
    z-index: 7;
}

.title {
    font-family: 'Open Sans', sans-serif;
    color: #3751FE;
    font-style: normal;
    font-size: 36px;
    line-height: 49px;
    font-weight: 700;
    margin-top: 9rem;
    margin-left: 15rem;
}

.sub-title {
    font-family: 'Roboto', sans-serif;
    color: rgba(0, 0, 0, 0.6);
    font-style: normal;
    font-size: 18px;
    line-height: 21px;
    font-weight: 400;
    margin-top: 1rem;
    margin-left: 15rem;
}

.box-a {
    background: #FFFFFF;
    border: 1px solid #C1BBBB;
    width: 554px;
    height: 74px;
    margin-top: 1rem;
    margin-left: 15rem;
    display: flex;
    flex-direction: column;
    overflow: hidden;
}

.box-a::before {
    content: "";
    border-left: 2px solid #0C31F1;
    /* Ajusta el color y el estilo de la línea según tus necesidades */
    display: block;
    height: 11.1%!important;
    /* Asegúrate de que el elemento contenedor tenga una altura definida */
    position: absolute;
}

.box-b {
    background: #FFFFFF;
    border: 1px solid #C1BBBB;
    width: 554px;
    height: 74px;
    margin-top: 0rem;
    margin-left: 15rem;
    display: flex;
    flex-direction: column;
}

.border-none {
    border: none;
    margin-top: 10px;
    margin-left: 2rem;
    width: auto;
    height: auto;
    left: 326px;
    top: 552px;
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 21px;
    color: #3751FE;
}

.border-none:focus {
    outline: none;
}

.fixed-text {
    font-family: 'Roboto', sans-serif;
    color: rgba(0, 0, 0, 0.61);
    width: auto;
    height: 21px;
    left: 326px;
    top: 512px;
    font-weight: 400;
    margin-top: 3px;
    font-style: normal;
    font-size: 18px;
    line-height: 21px;
    margin-left: 2rem;
}

.btn-login {
    font-family: 'Roboto', sans-serif;
    font-size: 18px;
    background: #3751FE;
    border: none;
    width: 129px;
    height: 54px;
    margin-top: 5rem;
    margin-left: 15rem;
    box-shadow: 0px 4px 3px rgba(0, 0, 0, 0.25);
    margin-bottom: 3rem;
    color: #FFFFFF;
    cursor: pointer;
    transform: translateX(1%);

    /* Estilos para :active */
    transition: transform 0.2s;
    /* Agrega una transición suave */
}

.btn-login:active {
    transform: translateY(2px);
    /* Desplaza el botón hacia abajo al hacer clic */
}

.btn-signup {
    font-family: 'Roboto', sans-serif;
    font-size: 18px;
    background: #FFFFFF;
    border: 1px solid #3751FE;
    color: #0C31F1;
    width: 129px;
    height: 54px;
    margin-top: 5rem;
    margin-left: 2rem;
    box-shadow: 0px 4px 3px rgba(0, 0, 0, 0.25);
    cursor: pointer;
    transform: translateX(1%);
    transition: transform 0.2s;
}

.btn-signup:active {
    transform: translateY(2px);
    /* Desplaza el botón hacia abajo al hacer clic */
}

.facebook {
    font-family: 'Roboto', sans-serif;
    width: 79px;
    height: 21px;
    left: 471px;
    top: 836px;
    font-style: normal;
    font-weight: 700;
    font-size: 18px;
    line-height: 21px;
    color: #3751FE;
}

.linkedin {
    font-family: 'Roboto', sans-serif;
    width: 70px;
    height: 21px;
    left: 625px;
    top: 836px;
    font-style: normal;
    font-weight: 700;
    font-size: 18px;
    line-height: 21px;
    color: #3751FE;
}

.google {
    font-family: 'Roboto', sans-serif;
    width: 58px;
    height: 21px;
    left: 768px;
    top: 836px;
    font-style: normal;
    font-weight: 700;
    font-size: 18px;
    line-height: 21px;
    color: #3751FE;
}

.login-with {
    font-family: 'Roboto', sans-serif;
    width: 101px;
    height: 21px;
    left: 295px;
    top: 836px;
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 21px;
    color: rgba(0, 0, 0, 0.61);
}

.marca-agua {
    font-family: 'Poppins', sans-serif;
    cursor: pointer;
    width: 177px;
    height: 46px;
    left: 956px;
    top: 857px;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 21px;
    text-align: center;
    /* color: honeydew; */
    color:black;
    margin-left: 40rem;
}
.marca-agua a {
    text-decoration: none;
    color: inherit;
}

.redes {
    /* background-color: red; */
    margin-top: 0rem;
    /* margin-left: 15rem; */
}

.redes>* {
    padding-right: 5rem;
}

.cover {
    /* background-color: yellow; */
    background-image: url('../img/man.svg');
    background-repeat: no-repeat;
    background-size: contain;
    background-position: right 70px top;
}

.remem-pass {
    margin-left: 15rem;
    margin-top: 1rem;
}

.list-button {
    margin-left: 2rem;
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 21px;
    color: rgba(0, 0, 0, 0.61);
}

.frg-password {
    margin-top: 1rem;
    margin-left: 17rem;
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 21px;
    color: rgba(0, 0, 0, 0.61);
}

.list-button::before {
    content: "";
    display: inline-block;
    width: 13px;
    height: 13px;
    border: 1px solid #E5E5E5;
    border-radius: 10%;
    margin-right: 5px;
    background-color: #FFFFFF;
}

@media (max-width: 768px) {
    body {
        background-image: none;
    }

    .btn-login:active {
        transform: translateY(2px);
        /* Desplaza el botón hacia abajo al hacer clic */
    }

    .formulario {
        background-image: none;
        margin-bottom: 0;
        max-width: 100%;
    }

    .title {

        font-size: 26px;
        line-height: 36px;
        font-weight: 700;
        background-image: url('../img/man.svg');
        background-repeat: no-repeat;
        background-position: center;
        background-size: contain;
        padding: 10px;
        background-position: right 0px top;
        margin-left: 0rem;
        margin-top: 10rem;
        margin-right: 0rem;
        max-width: 100%;
    }

    .sub-title {
        font-size: 16px;
        line-height: 18px;
        margin-left: 1rem;
    }

    .box-a {
        width: 100%;
        height: 74px;
        margin-right: 1rem;
        margin-left: 0;
    }

    .box-a::before {
        height: 13%;
    }

    .box-b {
        width: 100%;
        height: 74px;
        margin-right: 1rem;
        margin-left: 0;
    }

    .border-none {
        margin-left: 1rem;
        max-width: 100%;
        left: auto;
        top: auto;
    }

    .fixed-text {

        margin-left: 1rem;
        width: 100%;
        left: auto;
        top: auto;
    }

    .btn-login {
        margin-left: 2rem;
    }

    .btn-signup {
        margin-left: 2rem;
    }

    .redes {
        margin-left: 0rem;
        margin-bottom: 5rem;
    }

    .facebook {
        /* margin-left: 1rem; Ejemplo de cambio en el margen izquierdo */
        max-width: 100%;
        /* Cambia el ancho para que ocupe todo el espacio disponible */
        left: auto;
        /* Restablece la posición horizontal */
        top: auto;
        /* Restablece la posición vertical */
        text-align: center;
    }

    .linkedin {
        max-width: 100%;
        left: auto;
        top: auto;
    }

    .google {
        max-width: 100%;
        left: auto;
        top: auto;
    }

    .login-with {
        /* margin-left: 1rem;  */
        max-width: 100%;
        left: auto;
        top: auto;
        margin-left: 0;
    }

    .marca-agua {
        display: none;
    }

    .cover {
        background-image: none;
        /* background-color: yellow; */
    }

    .remem-pass {
        margin-left: 0rem;
        margin-top: 1rem;
        /* max-width: 100%; */
    }

    .list-button {
        max-width: 100%;
    }

    .frg-password {
        margin-left: 15rem;
        max-width: 100%;
    }
}

@media (max-width: 560px) {
    .title {
        margin-top: 3rem;
    }

    .list-button {
        margin-left: 0rem;
    }

    .frg-password {
        margin-left: 7rem;
        max-width: 100%;
    }

    .redes {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        margin-bottom: 0rem;
        margin-left: 5rem;
    }

    .box-a::before {
        height: 11.5%;
    }
}
 
@media (max-width: 384px) {
    .title {
        font-size: 26px;
        line-height: 36px;
        font-weight: 700;
        background-image: url('../img/man.svg');

        background-repeat: no-repeat;
        background-position: center;
        background-size: contain;
        padding: 10px;
        background-position: right -25px top;
        margin-left: 0rem;
        margin-top: 2.5rem;
        margin-right: 0rem;
        max-width: 100%;

    }

    .box-a::before {
        height: 11%;
    }

    .frg-password {
        margin-left: 5rem;
    }
}

@media (max-width: 320px) {
    .title {
        background-image: none;
        margin-left: -0.5rem;
    }

    .sub-title {
        margin-left: 0rem;
    }

    .box-a::before {
        height: 10.5%;
    }

    .remem-pass {
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    .frg-password {
        margin-left: 0rem;
    }

    .btn-login {
        margin-left: 4rem;
        margin-bottom: 1rem;
        margin-top: 2rem;
    }

    .btn-signup {
        margin-top: 0rem;
        margin-left: 4rem;
        margin-bottom: 2rem;
    }
}
@media (max-width: 240px) { 
    .box-a::before {
        height: 10%;
    }
    
 } 

@media (min-width: 769px) {
    body {
        background-image: none;
    }

    .formulario {
        background-image: none;
    }

    .cover {
        background-image: url('../img/man.svg');
        /* background-image: none; */
        background-repeat: no-repeat;
        background-size: contain;
        background-position: right -130px top;
    }

    .title {
        margin-left: 3rem;
        margin-top: 12rem;
        font-size: 29px;
        line-height: 40px;

    }

    .sub-title {
        margin-left: 3rem;
        margin-top: 3rem;
    }

    .box-a {
        margin-left: 3rem;
        width: 446px;
        height: 60px;
    }

    .box-a::before {
        height: 9.5%;
    }

    .box-b {
        width: 446px;
        height: 60px;
        /* margin-right: 1rem; */
        margin-left: 3rem;
    }

    .remem-pass {
        margin-left: 3rem;

    }

    .frg-password {
        margin-left: 9rem;
    }

    .btn-login {
        margin-left: 3rem;
    }

    .redes {
        margin-left: 3rem;
    }

    .redes>* {
        padding-right: 2rem;
    }

    .marca-agua {
        margin-left: 34rem;
    }
}

@media (min-width: 1024px) {
    .cover {
        background-position: right 70px top;
    }

    .formulario {
        /* background-image: url('../img/dots.svg'); */
    }

    body {
        /* background-image: url('../img/Deco.svg'); */
        background-repeat: no-repeat;
        background-position: right;
        background-size: contain;
        background-color: #F5F5F5;
        height: 100%;
    }

    .title {
        margin-left: 3rem;
        margin-top: 7rem;
        font-size: 29px;
        line-height: 40px;
    }

    .sub-title {
        margin-left: 3rem;
    }

    .box-a {
        margin-left: 3rem;
        width: 446px;
        height: 60px;
        /* margin-right: 1rem; */
    }

    .box-b {

        width: 446px;
        height: 60px;
        /* margin-right: 1rem; */
        margin-left: 3rem;
    }

    .remem-pass {
        margin-left: 3rem;

    }

    .frg-password {
        margin-left: 9rem;
    }

    .btn-login {
        margin-left: 3rem;
    }

    .redes {
        margin-left: 3rem;
    }

    .redes>* {
        padding-right: 2rem;
    }

    .marca-agua {
        margin-left: 34rem;
    }
}

@media (min-width: 1280px) {
    body{
        background-image: none;
    }
    .title {
        margin-left: 11rem;
        margin-top: 9rem;
        font-size: 36px;
        line-height: 49px;
    }

    .sub-title {
        margin-left: 11rem;

    }

    .box-a {
        width: 554px;
        height: 74px;
        margin-left: 11rem;
        /* margin-right: 1rem; */
    }

    .box-a::before {
        height: 11%;
    }

    .box-b {
        width: 554px;
        height: 74px;
        margin-left: 11rem;
    }

    .remem-pass {
        margin-left: 11rem;

    }
    .frg-password{
        margin-left: 15rem;
    }

    .btn-login {
        margin-left: 11rem;
    }

    .redes {
        margin-left: 11rem;
    }

    .redes>* {
        padding-right: 5rem;
    }

    .marca-agua {
        margin-left: 39rem;
    }
}

@media (min-width: 1300px) {
    body{
        background-image: none;
    }
    .marca-agua {
        margin-left: 36.5rem;
    }

    .cover {
        background-position: right 85px top;
    }

}

@media (min-width: 1380px) {
    body{
        background-image: none;
    }
    .marca-agua {
        margin-left: 48rem;
    }
    .frg-password{
        margin-left: 15rem;
    }
}

@media (min-width: 1450px) {
    body{
        background-image: none;
    }

    .title {

        margin-left: 15rem;
    }

    .sub-title {

        margin-left: 15rem;
    }

    .box-a {

        margin-left: 15rem;

    }

    .box-a::before {

        height: 11%;

    }

    .box-b {

        margin-left: 15rem;

    }

    .border-none {

        margin-left: 2rem;

    }



    .fixed-text {

        width: auto;
        height: 21px;

    }

    .btn-login {

        margin-left: 15rem;

    }



    .btn-signup {

        margin-left: 2rem;

    }


    .marca-agua {

        margin-left: 40rem;
    }

    .redes {

        margin-left: 15rem;
    }



    .remem-pass {
        margin-left: 15rem;

    }

    .frg-password {

        margin-left: 17rem;

    }
}

@media (min-width: 1800px) {
    body{
        background-image: none;
    }
    .formulario {
        background-size: 351px;
    }

    .title {
        font-size: 44px;
        line-height: 59px;
        margin-left: 30rem;
    }

    .sub-title {
        font-size: 22px;
        line-height: 26px;
        margin-left: 30rem;
    }

    .box-a {
        width: 665px;
        height: 89px;
        margin-left: 30rem;
    }

    .box-a::before {
        height: 11.5%;
    }

    .box-b {
        margin-left: 30rem;
        width: 665px;
        height: 89px;
    }

    .border-none {
        margin-top: 1rem;
        font-size: 22px;
        line-height: 26px;
    }



    .fixed-text {
        margin-top: 0.5rem;
        font-size: 22px;
        line-height: 26px;
    }

    .btn-login {
        width: 155px;
        height: 65px;
        font-size: 22px;
        margin-left: 30rem;
    }



    .btn-signup {
        font-size: 22px;
        width: 155px;
        height: 65px;
        margin-left: 2rem;
    }



    .facebook {
        font-size: 22px;
        line-height: 26px;
    }

    .linkedin {
        font-size: 22px;
        line-height: 26px;
    }

    .google {
        font-size: 22px;
        line-height: 26px;
    }

    .login-with {
        font-size: 22px;
        line-height: 26px;
    }

    .marca-agua {
        font-size: 17px;
        line-height: 26px;
        margin-left: 56rem;
        /* color: red; */
    }

    .redes {
        margin-left: 30rem;
    }

    .redes>* {
        padding-right: 7rem;
    }



    .remem-pass {
        margin-left: 30rem;
    }

    .list-button {
        margin-left: 1rem;
        font-size: 22px;
        line-height: 26px;

    }

    .frg-password {
        margin-left: 20rem;
        font-size: 22px;
        line-height: 26px;
    }

    .list-button::before {
        width: 16px;
        height: 16px;
        margin-right: 5px;
    }
}

@media (min-width: 2560px) {
    body{
        background-image: none;
    }
    .formulario {
        background-image: url('../img/dots.svg');
        background-repeat: no-repeat;
        background-size: 584px;
        background-position: right 0px top 6rem;
        margin-bottom: 0;
        position: relative;
    }

    .title {
        margin-top: 15rem;
        margin-left: 44rem;
        font-size: 65px;
        line-height: 83px;
    }

    .sub-title {
        margin-left: 44rem;
        font-size: 36px;
        line-height: 42px;
        margin-top: 5rem;
        margin-bottom: 7rem;
    }

    .box-a {
        margin-left: 44rem;
        width: 832px;
        height: 112px;
    }

    .box-a::before {
        height: 11.7%;
    }

    .box-b {
        margin-left: 44rem;
        width: 832px;
        height: 112px;
    }

    .border-none {
        font-size: 28px;
        line-height: 36px;
        margin-top: 1.8rem;
    }

    .fixed-text {
        font-size: 32px;
        line-height: 42px;
        margin-top: 0.5rem;
    }

    .list-button {
        font-size: 36px;
        line-height: 42px;
    }

    .list-button::before {
        width: 26px;
        height: 26px;
    }

    .remem-pass {
        margin-left: 44rem;
    }

    .frg-password {
        font-size: 36px;
        line-height: 42px;
    }

    .btn-login {
        margin-left: 44rem;
        width: 258px;
        height: 108px;
        margin-top: 5rem;
        font-size: 40px;
        line-height: 46px;
    }

    .btn-signup {
        margin-left: 5rem;
        width: 258px;
        height: 108px;
        margin-top: 5rem;
        font-size: 40px;
        line-height: 46px;
    }

    .redes {
        margin-left: 22rem;
    }

    .login-with {
        margin-left: 23rem;
        font-size: 36px;
        line-height: 42px;
    }

    .marca-agua {
        display: none;
    }

    .google {
        font-size: 36px;
        line-height: 42px;
        padding-left: 2rem;
    }

    .facebook {
        font-size: 36px;
        line-height: 42px;
        padding-left: 2rem;
    }

    .linkedin {
        font-size: 36px;
        line-height: 42px;
        padding-left: 2rem;
    }
}