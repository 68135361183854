/* Gota de agua en rutas públicas , estilos pasados por props */
#registrar {
    /* /registrar */
    position: fixed;
    background-color: #7492FF;
    height: 98%;
    width: 520px;
    position: absolute;
    top: 0;
    right: 0;
    /* bottom: 0; */
    border-radius: 45px 0% 45px 45px;
    border: 0px;
    z-index: 2;
    /* z-index: -1; */
}
#login{
    position: fixed;
    background-color: #7492FF;
    top: 0;
    right: 0;
    height: 98%;
    width: 520px;
    border-radius: 45px 0% 45px 45px;
    border: 0px;
    z-index: 2;
    /* z-index: -1; */
}
#home {
    position: fixed;
    background-color: #7492FF;
    height: 98%!important;
    width: 430px;
    top: 0;
    right: 0;
    border-radius: 45px 0% 45px 45px;
    border: 0px;
    z-index: 2;
    /* z-index: -1; */
}

@media (min-width: 1561px)and (max-width: 2000px){  
    #registrar {
        position: fixed;
        height: 98%;
        width: 500px!important;
    }
}

/* screens arriba de laptop promedio */
@media (min-width: 1081px) and (max-width: 1200px){
    #registrar {
        position: fixed;
        width: 375px;
        height: 98%;   
    }
    #login {
        width: 400px;
    }
}
/* controlar imagen para pantallas medianas  */
@media (min-width: 1201px) and (max-width: 1560px){
    #registrar {
        position: fixed;
        width: 325px;
        height: 98%;   
    }
    #login {
        width: 417px;
    }
    #home{
        height: 98%;
    }
}
/* antes 390 */
@media (max-width: 699px){
    #registrar {
        display: none;
    }
    #login {
        display: none;
    }
    #home{
        display: none;
    }
}
@media(min-width : 700px)and (max-width :1080px){
    #registrar {
        display: none!important;
    }
    #login {
        /* display: none; */
        width: 30%;
    }
    #home{
        /* width: 300px;
        height: 90%!important; */
        display: none;
    }
}

/* XL resolution Devices */
@media (min-width: 2001px)and (max-width: 3200px){
    #registrar {
        position: fixed;
        width: 595px;
        /* z-index: -3; */
    }
    #login {
        width: 480px;
    }
    #home{
        width: 520px;
    }
}