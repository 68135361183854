/* .backendErrorHandler{
    background-color: rebeccapurple;
    margin-left: 4%;
} */


@media (max-width: 800px) {
    .backendErrorHandler {
      margin-left: 0;
    }
    .responsive {
        justify-content: center;
    }
}
/* @media (min-width: 1920px) {
    .backendErrorHandler {
      margin-left: 25%;
    }
} */